import React, { FC } from 'react';

import BenefitsItem from 'components/elements/BenefitsItem';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';
import Button from 'components/elements/Button';

import { IPropsBenefitsSection } from './models';

import './BenefitsSection.scss';

const BenefitsSection: FC<IPropsBenefitsSection> = ({ data: [data] }) => {
  const { items, title, sectionSettings, ctaCard } = data;

  return (
    <BaseSection data={sectionSettings}>
      <div data-testid="BenefitsSection" className="benefits-section">
        <Typography data={title} customClass="benefits-section__title" />
        <div className="benefits-section__items">
          {items.map((item) => (
            <BenefitsItem key={item.title[0].text} data={[item]} />
          ))}
        </div>
        {data?.ctaCard?.length ? (
          <div className="benefits_section__cta">
            <Button data={data.ctaCard} />
          </div>
        ) : null}
      </div>
    </BaseSection>
  );
};

export default BenefitsSection;
